.relative[data-v-772cf08a] {
  position: relative;
}
.w-full[data-v-772cf08a] {
  width: 100%;
}
.relative-center[data-v-772cf08a] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-772cf08a] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-772cf08a] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-772cf08a] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-772cf08a] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-772cf08a] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-772cf08a] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-772cf08a] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-772cf08a] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-772cf08a] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-772cf08a] {
  justify-content: space-between;
}
.flex[data-v-772cf08a] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-772cf08a] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-772cf08a],
.card-list-no-padding .ci-description[data-v-772cf08a] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.introduce-list[data-v-772cf08a] {
  display: flex;
  flex-direction: column;
  gap: 0.41666667rem;
}
.introduce-list .il-item[data-v-772cf08a] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.introduce-list .il-item .full-center[data-v-772cf08a] {
  left: 0 !important;
  transform: translateY(-50%) !important;
}
.introduce-list .il-item.revert[data-v-772cf08a] {
  justify-content: flex-end;
}
.introduce-list .il-item.revert .il-info[data-v-772cf08a] {
  grid-template-areas: "b a" "c f" "d f" "g e";
}
.introduce-list .il-item.revert .full-center[data-v-772cf08a] {
  left: unset !important;
  right: 0;
  transform: translateY(-50%) !important;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.introduce-list .il-item.revert .il-image-box[data-v-772cf08a] {
  right: unset;
  left: 0;
  text-align: right;
}
.introduce-list .il-item.revert .il-description[data-v-772cf08a] {
  justify-self: flex-end;
}
.introduce-list .il-item .il-info[data-v-772cf08a] {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto auto;
  align-items: center;
  grid-template-areas: "a b" "f c" "f d" "e g";
  column-gap: 0.10416667rem;
}
.introduce-list .il-item .il-hr[data-v-772cf08a] {
  grid-area: a;
  width: 0.41666667rem;
  height: 0.02083333rem;
  background: #4e99fd;
  align-self: center;
}
.introduce-list .il-item .il-en[data-v-772cf08a] {
  grid-area: b;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.125rem;
  color: #ddd;
  white-space: nowrap;
}
.introduce-list .il-item .il-title[data-v-772cf08a] {
  grid-area: c;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.125rem;
  color: #4e99fd;
  margin-top: 0.07291667rem;
}
.introduce-list .il-item .il-description[data-v-772cf08a] {
  grid-area: d;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.09375rem;
  color: #666;
  line-height: 0.1875rem;
  margin-top: 0.41666667rem;
  width: 2.58333333rem;
}
.introduce-list .il-item .il-index[data-v-772cf08a] {
  grid-area: e;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.125rem;
  color: #666;
  margin-top: 0.3125rem;
}
.introduce-list .il-item .il-image-box[data-v-772cf08a] {
  position: absolute;
  width: 3.64583333rem;
  right: 0;
  background: #f8f8f8;
}