.relative[data-v-617fc3ce] {
  position: relative;
}
.w-full[data-v-617fc3ce] {
  width: 100%;
}
.relative-center[data-v-617fc3ce] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-617fc3ce] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-617fc3ce] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-617fc3ce] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-617fc3ce] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-617fc3ce] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-617fc3ce] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-617fc3ce] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-617fc3ce] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-617fc3ce] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-617fc3ce] {
  justify-content: space-between;
}
.flex[data-v-617fc3ce] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-617fc3ce] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-617fc3ce],
.card-list-no-padding .ci-description[data-v-617fc3ce] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.nav[data-v-617fc3ce] {
  font-size: 0;
  position: relative;
  width: 100%;
}
.parent[data-v-617fc3ce] {
  position: relative;
  width: 100%;
  padding-top: 46.67%;
}
.banner[data-v-617fc3ce] {
  width: 100%;
}
.content[data-v-617fc3ce] {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1000;
  height: 0.52083333rem;
  transition: .5s all;
}
.content .content-wrap[data-v-617fc3ce] {
  margin: 0 auto;
}
.content-inner[data-v-617fc3ce] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo[data-v-617fc3ce] {
  width: 1.20833333rem;
  pointer-events: none;
}
.tabs[data-v-617fc3ce] {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}
.list[data-v-617fc3ce] {
  color: #fff;
  font-size: 0.125rem;
  display: flex;
  align-items: stretch;
  height: 0.52083333rem;
}
.query[data-v-617fc3ce] {
  margin-left: 0.52083333rem;
}
.query .button[data-v-617fc3ce] {
  width: 0.69270833rem;
  height: 0.52083333rem;
  display: none;
  cursor: pointer;
}
.phone-content[data-v-617fc3ce] {
  display: flex;
}
.phone[data-v-617fc3ce] {
  font-size: 0.125rem;
  color: #333;
  display: flex;
  align-items: center;
  margin-left: 0.3125rem;
  white-space: nowrap;
}
.phone .phone-icon[data-v-617fc3ce] {
  width: 0.13541667rem;
  margin-right: 0.04166667rem;
  margin-left: 0.15625rem;
}
.white .phone[data-v-617fc3ce] {
  color: #fff;
}
.item[data-v-617fc3ce] {
  padding: 0 0.16666667rem;
  height: 0.52083333rem;
  line-height: 1;
  position: relative;
  cursor: pointer;
  font-size: 0.125rem;
  color: #666;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item .inner[data-v-617fc3ce] {
  width: 100%;
  position: relative;
}
.item .inner[data-v-617fc3ce]::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.02083333rem;
  background: transparent;
  top: 100%;
  left: 0;
  margin-top: 0.04166667rem;
}
.item:hover .child[data-v-617fc3ce] {
  display: block;
}
.item .child[data-v-617fc3ce] {
  position: absolute;
  top: 100%;
  padding-top: 0.08333333rem;
  left: 0.16666667rem;
  width: 0.83333333rem;
  display: none;
}
.item .child .child-inner[data-v-617fc3ce] {
  border-radius: 0.02083333rem;
  box-shadow: 0 0 0.05208333rem rgba(80,80,80,.2);
}
.item .child .child-inner .child-content[data-v-617fc3ce] {
  background: #fff;
  border-radius: 0.02083333rem;
  padding: 0.08333333rem 0.08333333rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.10416667rem 0;
  position: relative;
}
.item .child .child-inner .child-content .child-list[data-v-617fc3ce] {
  width: 100%;
}
.item .child .child-inner .child-content .child-list .child-parent[data-v-617fc3ce] {
  display: grid;
  grid-template-columns: 0.10416667rem 1fr;
  align-items: center;
  gap: 0.04166667rem;
  font-size: 0.07291667rem;
  color: #333;
  margin-bottom: 0.0625rem;
}
.item .child .child-inner .child-content .child-list .child-parent .child-icon[data-v-617fc3ce] {
  height: 0.08854167rem;
  width: 0.08854167rem;
}
.item .child .child-inner .child-content .child-list .child-item[data-v-617fc3ce] {
  font-size: 0.07291667rem;
  color: #999;
  line-height: 0.11458333rem;
}
.item .child .child-inner .child-content .child-list .child-item[data-v-617fc3ce]:hover {
  color: #4e99fd;
}
.item .child .child-inner .child-content .child-list .child-active[data-v-617fc3ce] {
  color: #4e99fd;
}
.item .child .child-inner .child-content[data-v-617fc3ce]::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 0.0625rem solid transparent;
  border-right: 0.0625rem solid transparent;
  border-bottom: 0.07291667rem solid #fff;
  bottom: 100%;
  left: 0.1875rem;
}
.item[data-v-617fc3ce]:hover,
.active[data-v-617fc3ce] {
  color: #333;
}
.item:hover .inner[data-v-617fc3ce]::after,
.active .inner[data-v-617fc3ce]::after {
  background: #4e99fd;
}
.white .item[data-v-617fc3ce] {
  color: #fff;
}
.white .item:hover .inner[data-v-617fc3ce]::after,
.white .active .inner[data-v-617fc3ce]::after {
  background: #fff;
}
.hover[data-v-617fc3ce] {
  position: absolute;
  left: 50%;
  top: 0.52083333rem;
  height: calc(100% - 0.52083333rem);
  transform: translateX(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.hover .txt[data-v-617fc3ce] {
  margin-top: 0.41666667rem;
  line-height: 1;
  padding: 0 0.10416667rem;
}
.hover .digital[data-v-617fc3ce] {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  transform: translateY(-50%);
}
.banner-list[data-v-617fc3ce] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.banner-list .banner-item[data-v-617fc3ce] {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s all;
  text-align: center;
}
.indicator[data-v-617fc3ce] {
  position: absolute;
  bottom: 0.33333333rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 0.125rem;
}
.indicator .indicator-item[data-v-617fc3ce] {
  width: 0.10416667rem;
  height: 0.10416667rem;
  border-radius: 50%;
  background: #fff;
  opacity: .4;
  cursor: pointer;
}
.indicator .indicator-item[data-v-617fc3ce]:hover {
  opacity: .8;
}
.indicator .indicator-item-active[data-v-617fc3ce] {
  opacity: .8;
}
.scrolling[data-v-617fc3ce] {
  background: #fff;
  box-shadow: 0 0.00520833rem 0.05208333rem #999;
}
.scrolling .phone[data-v-617fc3ce] {
  color: #333;
}
.scrolling .item[data-v-617fc3ce] {
  color: #666;
}
.scrolling .item:hover .inner[data-v-617fc3ce]::after,
.scrolling .active .inner[data-v-617fc3ce]::after {
  background: #4e99fd;
}
.banner-top[data-v-617fc3ce] {
  margin-top: 0.88541667rem;
}