.relative[data-v-fbc4c30e] {
  position: relative;
}
.w-full[data-v-fbc4c30e] {
  width: 100%;
}
.relative-center[data-v-fbc4c30e] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-fbc4c30e] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-fbc4c30e] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-fbc4c30e] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-fbc4c30e] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-fbc4c30e] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-fbc4c30e] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-fbc4c30e] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-fbc4c30e] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-fbc4c30e] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-fbc4c30e] {
  justify-content: space-between;
}
.flex[data-v-fbc4c30e] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-fbc4c30e] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-fbc4c30e],
.card-list-no-padding .ci-description[data-v-fbc4c30e] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.container[data-v-fbc4c30e] {
  width: 100%;
  height: 0.44270833rem;
  background-color: #353a5bd8;
  position: fixed;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.title[data-v-fbc4c30e] {
  color: #fff;
  font-size: 0.125rem;
}
.code-img[data-v-fbc4c30e] {
  background: #fff;
  height: 0.21875rem;
  margin: 0 0.10416667rem;
}
.submit-btn[data-v-fbc4c30e] {
  background: #2f74ff;
  color: #fff;
  font-size: 0.09375rem;
  padding: 0.0625rem;
  cursor: pointer;
}
.input-css[data-v-fbc4c30e] {
  flex-grow: 1;
  background: #fff;
  border: 0.00520833rem solid #ccc;
  font-size: 0.08333333rem;
  height: 0.20833333rem;
  padding: 0 0.10416667rem;
  outline: none;
  margin-left: 0.10416667rem;
}
.mask[data-v-fbc4c30e] {
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(10,10,10,.6);
}
.hover-pop-check-icon[data-v-fbc4c30e] {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.hover-pop-success[data-v-fbc4c30e] {
  display: inherit;
  width: 3.421875rem;
  height: 2.5rem;
  padding: 0.20833333rem 0;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0.08333333rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}
.hover-pop-success .hover-pop-success-img[data-v-fbc4c30e] {
  width: 0.9375rem;
  margin: 0 auto;
}
.hover-pop-success .hover-pop-success-title[data-v-fbc4c30e] {
  color: #1dda67;
  font-size: 0.1875rem;
  margin-top: 0.10416667rem;
}
.hover-pop-success .hover-pop-success-subtitle[data-v-fbc4c30e] {
  color: #666;
  font-size: 0.08333333rem;
  margin-top: 0.125rem;
  margin-bottom: 0.13020833rem;
}
.hover-pop-success .hover-pop-success-info[data-v-fbc4c30e] {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 0.09375rem;
  justify-content: center;
}
.hover-pop-success .hover-pop-success-phone[data-v-fbc4c30e] {
  margin-right: 0.04166667rem;
}
.hover-pop-success .hover-pop-success-sp[data-v-fbc4c30e] {
  font-size: 0.125rem;
  color: #3097ef;
  margin-left: 0.08333333rem;
}
.hover-pop-success .hover-pop-close[data-v-fbc4c30e] {
  position: absolute;
  width: 0.10416667rem;
  right: 0.125rem;
  top: 0.125rem;
  cursor: pointer;
}