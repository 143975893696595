.relative[data-v-e1ab3db8] {
  position: relative;
}
.w-full[data-v-e1ab3db8] {
  width: 100%;
}
.relative-center[data-v-e1ab3db8] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-e1ab3db8] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-e1ab3db8] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-e1ab3db8] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-e1ab3db8] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-e1ab3db8] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-e1ab3db8] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-e1ab3db8] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-e1ab3db8] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-e1ab3db8] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-e1ab3db8] {
  justify-content: space-between;
}
.flex[data-v-e1ab3db8] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-e1ab3db8] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-e1ab3db8],
.card-list-no-padding .ci-description[data-v-e1ab3db8] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.structure[data-v-e1ab3db8] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure .card-box[data-v-e1ab3db8] {
  margin-bottom: 0.41666667rem;
}
.structure .img[data-v-e1ab3db8] {
  width: 100%;
  display: block;
  margin-top: 0.20833333rem;
}
.advantage[data-v-e1ab3db8] {
  height: 3.97916667rem;
  background: url(@/asset/smart-port/ic_port_10.png) no-repeat center/cover;
  text-align: center;
  overflow: hidden;
}
.advantage .en[data-v-e1ab3db8] {
  font-size: 0.1875rem;
  color: rgba(255,255,255,.4);
  margin-top: 0.41666667rem;
}
.advantage .title[data-v-e1ab3db8] {
  font-family: YouSheBiaoTiHei;
  margin-top: 0.0625rem;
  font-size: 0.1875rem;
  color: #fff;
}
.advantage .brief[data-v-e1ab3db8] {
  font-size: 0.10416667rem;
  color: rgba(255,255,255,.6);
  margin-top: 0.17708333rem;
}
.advantage .tabs[data-v-e1ab3db8] {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.41666667rem;
  border-bottom: 0.01041667rem solid rgba(255,255,255,.6);
  position: relative;
  margin-top: 0.41666667rem;
}
.advantage .tabs .item[data-v-e1ab3db8] {
  text-align: center;
  font-size: 0.10416667rem;
  color: #fff;
  padding-bottom: 0.20833333rem;
  position: relative;
  cursor: pointer;
}
.advantage .tabs .item .icon[data-v-e1ab3db8] {
  width: 0.10416667rem;
}
.advantage .tabs .item .txt[data-v-e1ab3db8] {
  margin-top: 0.04166667rem;
}
.advantage .tabs .item-strip[data-v-e1ab3db8] {
  position: absolute;
  top: 100%;
  left: 0;
  height: 0.02083333rem;
  width: 33.3%;
  background: #4e99fd;
  margin-top: -0.00520833rem;
  transition: all .5s;
}
.advantage .content[data-v-e1ab3db8] {
  display: flex;
  align-items: flex-start;
  gap: 0.20833333rem;
  margin-top: 0.20833333rem;
}
.advantage .content .img[data-v-e1ab3db8] {
  width: 2.72916667rem;
}
.advantage .content .right[data-v-e1ab3db8] {
  text-align: left;
}
.advantage .content .right .right-title[data-v-e1ab3db8] {
  font-size: 0.14583333rem;
  color: #fff;
  margin-bottom: 0.05208333rem;
}
.advantage .content .right .right-brief[data-v-e1ab3db8] {
  font-size: 0.09375rem;
  color: rgba(255,255,255,.8);
  line-height: 2;
}
.application[data-v-e1ab3db8] {
  padding: 0.41666667rem 0;
  background: #f7fbff;
}