@font-face {
  font-family: AlibabaPuHuiTi-3-55-Regular;
  src: url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.eot) format('embedded-opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.otf) format('opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.ttf) format('TrueType'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff) format('woff'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff2) format('woff2');
}

@font-face {
  font-family: Source Han Sans CN-Medium;
  src: url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.eot) format('embedded-opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.otf) format('opentype'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.ttf) format('TrueType'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff) format('woff'),
    url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/AlibabaPuHuiTi-3-55-Regular.woff2) format('woff2');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(https://ow-static.oss-cn-chengdu.aliyuncs.com/font/YouSheBiaoTiHei.woff2) format('TrueType');
}

* {
  font-family: AlibabaPuHuiTi-3-55-Regular, serif;
}

.main {
  max-width: 7.5rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1500px) {
  .main {
    padding: 0 0.10416667rem;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 0.125rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  html {
    font-size: 0.125rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 0.125rem;
  }
}