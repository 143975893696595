.relative[data-v-eb13fa74] {
  position: relative;
}
.w-full[data-v-eb13fa74] {
  width: 100%;
}
.relative-center[data-v-eb13fa74] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-eb13fa74] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-eb13fa74] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-eb13fa74] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-eb13fa74] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-eb13fa74] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-eb13fa74] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-eb13fa74] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-eb13fa74] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-eb13fa74] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-eb13fa74] {
  justify-content: space-between;
}
.flex[data-v-eb13fa74] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-eb13fa74] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-eb13fa74],
.card-list-no-padding .ci-description[data-v-eb13fa74] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.get-plan-toast[data-v-eb13fa74] {
  position: fixed;
  background-color: #fff;
  background-size: 100% 100%;
  box-sizing: border-box;
  z-index: 9999;
  border-radius: 0.04166667rem;
  overflow: hidden;
  transition: all .2s;
  width: 0;
  height: 0;
  padding: 0;
  left: 0.20833333rem;
  top: 100%;
  transform: translate(0, calc(-100% - 0.20833333rem));
}
.get-plan-toast.show[data-v-eb13fa74] {
  width: 3.75rem;
  height: 2.734375rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.get-plan-toast *[data-v-eb13fa74] {
  box-sizing: border-box;
}
.get-plan-toast .private-close[data-v-eb13fa74] {
  position: absolute;
  right: 0.08333333rem;
  top: 0.04166667rem;
  width: 0.16666667rem;
  height: 0.16666667rem;
  cursor: pointer;
  background: url("./assets/close-img.png") center no-repeat;
  background-size: 100% 100%;
}
.get-plan-toast .gpt-title[data-v-eb13fa74] {
  font-weight: 400;
  font-size: 0.1875rem;
  color: #000;
  line-height: 0.3125rem;
  font-style: normal;
  text-transform: none;
}
.get-plan-toast .form-container[data-v-eb13fa74] {
  display: grid;
  padding: 0.10416667rem 0.15625rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 0.08333333rem;
  row-gap: 0.08333333rem;
}
.get-plan-toast .form-container .full[data-v-eb13fa74] {
  grid-column: 1/3;
  align-items: flex-start;
}
.get-plan-toast .form-container .full textarea[data-v-eb13fa74] {
  resize: none;
}
.get-plan-toast .form-container .full-textarea[data-v-eb13fa74] {
  height: 0.5rem;
  padding-top: 0.05208333rem;
}
.get-plan-toast .hover-pop-icon[data-v-eb13fa74] {
  width: 0.08333333rem;
  flex-shrink: 0;
}
.get-plan-toast .hover-pop-item[data-v-eb13fa74] {
  display: flex;
  align-items: center;
  color: red;
  border-radius: 0.02083333rem 0.02083333rem 0.02083333rem 0.02083333rem;
  border: 0.00520833rem solid #e8e8e8;
  padding: 0 0.0625rem;
  height: 0.1875rem;
}
.get-plan-toast .hover-pop-input[data-v-eb13fa74] {
  flex-grow: 1;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  border: none;
  font-size: 0.08333333rem;
  height: 0.1875rem;
  padding: 0 0.05208333rem;
  outline: none;
  color: #666;
  background-color: transparent;
  min-width: 0;
}
.get-plan-toast .hover-pop-input[data-v-eb13fa74]::placeholder {
  color: #969393;
}
.get-plan-toast .hover-pop-check-icon[data-v-eb13fa74] {
  object-fit: contain;
  height: 100%;
  cursor: pointer;
}
.get-plan-toast .private[data-v-eb13fa74] {
  font-size: 0.0625rem;
  color: #999;
  text-align: center;
  margin-top: 0.20833333rem;
}
.get-plan-toast .private .sp[data-v-eb13fa74] {
  color: #4e99fd;
  cursor: pointer;
}
.get-plan-toast .submit[data-v-eb13fa74] {
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.02604167rem;
  color: #fff;
  width: 1.04166667rem;
  height: 0.20833333rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.08333333rem;
  margin: 0 auto;
  margin-top: 0.0625rem;
  cursor: pointer;
}
.plan-toast-small[data-v-eb13fa74] {
  width: 0.41666667rem;
  position: fixed;
  transition: all .2s;
  left: 0.20833333rem;
  bottom: 0.20833333rem;
  opacity: 1;
  cursor: pointer;
}
.plan-toast-small.show[data-v-eb13fa74] {
  opacity: 0;
}
.privateBox[data-v-eb13fa74],
.hover-pop-success[data-v-eb13fa74] {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.privateBox .private-back[data-v-eb13fa74],
.hover-pop-success .private-back[data-v-eb13fa74] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
  z-index: 9999;
}
.privateBox .private-content[data-v-eb13fa74],
.hover-pop-success .private-content[data-v-eb13fa74] {
  position: fixed;
  width: 4.16666667rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.privateBox .private-content .private-title[data-v-eb13fa74],
.hover-pop-success .private-content .private-title[data-v-eb13fa74] {
  color: #fff;
  font-size: 0.08333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.3125rem;
  background: #4e99fd;
  position: relative;
}
.privateBox .private-content .private-title .private-close[data-v-eb13fa74],
.hover-pop-success .private-content .private-title .private-close[data-v-eb13fa74] {
  position: absolute;
  right: 0.125rem;
  top: 0.11458333rem;
  width: 0.08333333rem;
  cursor: pointer;
}
.privateBox .private-content .private-inner[data-v-eb13fa74],
.hover-pop-success .private-content .private-inner[data-v-eb13fa74] {
  font-size: 0.07291667rem;
  line-height: 2;
  background: #fff;
  text-align: left;
  padding: 0.20833333rem;
  padding-top: 0.05208333rem;
}
.privateBox .private-content .private-inner .private-h1[data-v-eb13fa74],
.hover-pop-success .private-content .private-inner .private-h1[data-v-eb13fa74] {
  color: #666;
}
.privateBox .private-content .private-inner .private-h2[data-v-eb13fa74],
.hover-pop-success .private-content .private-inner .private-h2[data-v-eb13fa74] {
  color: #333;
}
.privateBox .private-content .private-inner .private-h3[data-v-eb13fa74],
.hover-pop-success .private-content .private-inner .private-h3[data-v-eb13fa74] {
  color: #999;
}
.privateBox .private-content .private-inner .private-button[data-v-eb13fa74],
.hover-pop-success .private-content .private-inner .private-button[data-v-eb13fa74] {
  width: 0.75rem;
  height: 0.25rem;
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0.26041667rem;
  cursor: pointer;
}
.hover-pop-success[data-v-eb13fa74] {
  height: 3.05208333rem;
  width: 4.140625rem;
}
.hover-pop-success .private-back[data-v-eb13fa74] {
  display: none;
}
.hover-pop-success .private-inner[data-v-eb13fa74] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hover-pop-success .private-inner .success-txt[data-v-eb13fa74] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.08333333rem;
  color: #52c41a;
  line-height: 0.08333333rem;
}
.hover-pop-success .success-img[data-v-eb13fa74] {
  width: 1.875rem;
}
.banner-title[data-v-eb13fa74] {
  height: 0.83333333rem;
  background: url("./assets//banner-title.png") center no-repeat;
  background-size: 100% 100%;
  padding: 0 0.15625rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.desc-value[data-v-eb13fa74] {
  font-size: 0.09375rem;
  font-weight: 100;
}