.relative[data-v-b275d18e] {
  position: relative;
}
.w-full[data-v-b275d18e] {
  width: 100%;
}
.relative-center[data-v-b275d18e] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-b275d18e] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-b275d18e] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-b275d18e] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-b275d18e] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-b275d18e] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-b275d18e] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-b275d18e] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-b275d18e] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-b275d18e] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-b275d18e] {
  justify-content: space-between;
}
.flex[data-v-b275d18e] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-b275d18e] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-b275d18e],
.card-list-no-padding .ci-description[data-v-b275d18e] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.car-monitor .use-bg[data-v-b275d18e] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.car-monitor .b3-img[data-v-b275d18e] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 6.71354167rem;
}