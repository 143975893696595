.relative[data-v-8bbed360] {
  position: relative;
}
.w-full[data-v-8bbed360] {
  width: 100%;
}
.relative-center[data-v-8bbed360] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-8bbed360] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-8bbed360] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-8bbed360] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-8bbed360] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-8bbed360] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-8bbed360] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-8bbed360] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-8bbed360] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-8bbed360] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-8bbed360] {
  justify-content: space-between;
}
.flex[data-v-8bbed360] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-8bbed360] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-8bbed360],
.card-list-no-padding .ci-description[data-v-8bbed360] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.footer-submit[data-v-8bbed360] {
  margin: 0.20833333rem 0;
  display: flex;
  align-items: center;
}
.footer-submit .tj[data-v-8bbed360] {
  width: 1.04166667rem;
  height: 0.29166667rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 500;
  font-size: 0.125rem;
  color: #fff;
  text-align: center;
  line-height: 0.29166667rem;
  margin-left: 0.20833333rem;
}
.logo[data-v-8bbed360] {
  position: absolute;
  left: 50%;
  top: 0.10416667rem;
  transform: translateX(-50%);
}
.footer-input[data-v-8bbed360] {
  height: 0.28125rem !important;
}
.footer-input[data-v-8bbed360] ::-webkit-input-placeholder {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.10416667rem;
  color: #999;
}
.footer-input input[data-v-8bbed360] {
  width: 2.375rem !important;
  height: 0.28125rem !important;
  opacity: .4;
  border: none;
  border: 0.00520833rem solid #fff;
  background: transparent;
  line-height: 0.28125rem;
  padding: 0 0.10416667rem;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  outline: none;
  display: block;
  color: #fff;
  font-size: 0.10416667rem;
}
.footer-container[data-v-8bbed360] {
  width: 100%;
  padding-top: 0.20833333rem;
  background-image: url("./assets/footer-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #323537;
  position: relative;
}
.footer-container *[data-v-8bbed360] {
  cursor: default;
}
.footer-container .grid-box[data-v-8bbed360] {
  margin-top: 0.15625rem;
  display: grid;
  gap: 0.26041667rem;
  grid-template-columns: 1fr auto;
  justify-content: center;
  max-width: 6.25rem;
  margin: 0 auto;
}
.footer-container .qrcode-box-item[data-v-8bbed360] {
  display: flex;
  align-items: center;
}
.footer-container .qrcode-box[data-v-8bbed360] {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
}
.footer-container .qr-title[data-v-8bbed360] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.09375rem;
  color: #fff;
  margin-top: 0.08333333rem;
  text-stroke: 0.00520833rem rgba(0,0,0,0);
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 0.00520833rem rgba(0,0,0,0);
}
.footer-container .qr-explain[data-v-8bbed360] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: rgba(255,255,255,.6);
  margin-top: 0.08333333rem;
  text-stroke: 0.00520833rem rgba(0,0,0,0);
  text-align: left;
  font-style: normal;
  text-transform: none;
  -webkit-text-stroke: 0.00520833rem rgba(0,0,0,0);
}
.footer-container .left[data-v-8bbed360] {
  display: flex;
  gap: 0.20833333rem;
  grid-template-columns: 0.65104167rem 1.53645833rem;
}
.footer-container .left .company-info[data-v-8bbed360] {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.footer-container .left .company-info .company-item[data-v-8bbed360] {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
}
.footer-container .left .company-info .company-item .tips[data-v-8bbed360] {
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: #999;
}
.footer-container .left .company-info .company-item .company-head[data-v-8bbed360] {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Source Han Sans,Source Han Sans;
  font-weight: 700;
  font-size: 0.125rem;
  color: #fff;
  gap: 0.04166667rem;
}
.footer-container .left .ci-title[data-v-8bbed360] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.07291667rem;
  color: #fff;
}
.footer-container .left .ci-phone[data-v-8bbed360] {
  margin-top: 0.15625rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bolder;
  font-size: 0.125rem;
  color: #4e99fd;
}
.footer-container .left .ci-mail[data-v-8bbed360] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  color: rgba(255,255,255,.6);
  line-height: 0.125rem;
}
.footer-container .right[data-v-8bbed360] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-container .right .top-title[data-v-8bbed360] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
  cursor: pointer;
}
.footer-container .right .r-children[data-v-8bbed360] {
  margin-top: 0.125rem;
  display: flex;
  flex-direction: column;
  gap: 0.08333333rem;
}
.footer-container .right .r-children .r-child[data-v-8bbed360] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.08333333rem;
  color: rgba(255,255,255,.6);
  cursor: pointer;
  transition: color .2s;
}
.footer-container .right .r-children .r-child[data-v-8bbed360]:hover {
  color: #4e99fd;
}
.footer-container .copyright-box[data-v-8bbed360] {
  width: 100%;
  margin-top: 0;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  color: rgba(255,255,255,.4);
  border-top: 0.00520833rem solid rgba(255,255,255,.4);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.125rem 0;
}
.footer-container .copyright-box img[data-v-8bbed360] {
  width: 0.10416667rem;
  height: 0.11458333rem;
}
.footer-container .copyright[data-v-8bbed360] {
  margin-left: 0.15625rem;
  color: #707070;
  display: inline-flex;
  align-items: center;
  column-gap: 0.02604167rem;
}
.footer-container .copyright[data-v-8bbed360]:hover {
  color: gray;
}