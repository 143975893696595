.relative[data-v-444bb6f6] {
  position: relative;
}
.w-full[data-v-444bb6f6] {
  width: 100%;
}
.relative-center[data-v-444bb6f6] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-444bb6f6] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-444bb6f6] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-444bb6f6] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-444bb6f6] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-444bb6f6] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-444bb6f6] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-444bb6f6] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-444bb6f6] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-444bb6f6] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-444bb6f6] {
  justify-content: space-between;
}
.flex[data-v-444bb6f6] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-444bb6f6] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-444bb6f6],
.card-list-no-padding .ci-description[data-v-444bb6f6] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.display-list .block-content[data-v-444bb6f6] {
  display: flex;
  flex-direction: column;
  gap: 0.41666667rem;
}
.display-list .dl-item[data-v-444bb6f6] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.41666667rem;
}
.display-list .dl-board[data-v-444bb6f6] {
  object-fit: cover;
  width: 100%;
}
.display-list .dl-title[data-v-444bb6f6] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: bold;
  font-size: 0.125rem;
  color: #4e99fd;
}
.display-list .dl-hr[data-v-444bb6f6] {
  width: 0.41666667rem;
  height: 0.02083333rem;
  background: #4e99fd;
  margin-top: 0.08333333rem;
}
.display-list .dl-description[data-v-444bb6f6] {
  margin-top: 0.20833333rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.09375rem;
  color: #666;
  line-height: 0.1875rem;
  white-space: pre-wrap;
}
.display-list .dl-button[data-v-444bb6f6] {
  margin-top: 0.20833333rem;
  width: 1.13020833rem;
  height: 0.31770833rem;
  background: #4e99fd;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  cursor: pointer;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #fffdfd;
  display: flex;
  justify-content: center;
  align-items: center;
}