.relative[data-v-7597b928] {
  position: relative;
}
.w-full[data-v-7597b928] {
  width: 100%;
}
.relative-center[data-v-7597b928] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-7597b928] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-7597b928] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-7597b928] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-7597b928] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-7597b928] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-7597b928] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-7597b928] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-7597b928] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-7597b928] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-7597b928] {
  justify-content: space-between;
}
.flex[data-v-7597b928] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-7597b928] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-7597b928],
.card-list-no-padding .ci-description[data-v-7597b928] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.weakness[data-v-7597b928] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure[data-v-7597b928] {
  padding: 0.41666667rem 0;
  background: url(@/asset/smart-traffic/ic_traffic_36.png) no-repeat center/cover;
}
.structure .img[data-v-7597b928] {
  display: block;
  margin-top: 0.20833333rem;
}
.advantage[data-v-7597b928] {
  padding: 0.41666667rem 0;
}
.advantage .list[data-v-7597b928] {
  display: flex;
  gap: 0.125rem;
  margin-top: 0.41666667rem;
}
.advantage .list .item[data-v-7597b928] {
  width: 1.5625rem;
  height: 2.5rem;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  border: 0.00520833rem solid rgba(51,51,51,.2);
  position: relative;
}
.advantage .list .item .content[data-v-7597b928] {
  text-align: center;
  padding: 0.20833333rem;
  transition: all .5s;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.advantage .list .item .content .outline[data-v-7597b928] {
  width: 0.36458333rem;
  height: 0.36458333rem;
  position: relative;
  margin: 0 auto;
}
.advantage .list .item .content .outline .icon[data-v-7597b928] {
  width: 0.1875rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.advantage .list .item .content .outline[data-v-7597b928]::after {
  content: "";
  border: 0.00520833rem solid rgba(51,51,51,.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: rotate(45deg);
}
.advantage .list .item .content .title[data-v-7597b928] {
  font-size: 0.125rem;
  color: #333;
  margin-top: 0.26041667rem;
  margin-bottom: 0.04166667rem;
}
.advantage .list .item .content .en-title[data-v-7597b928] {
  font-size: 0.09375rem;
  color: #999;
}
.advantage .list .item .content[data-v-7597b928]::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0.46875rem;
  transform: translateX(-50%);
  width: 0.38541667rem;
  height: 0.04166667rem;
  background: #4e99fd;
  border-radius: 0.03645833rem 0.03645833rem 0.03645833rem 0.03645833rem;
}
.advantage .list .item .hover[data-v-7597b928] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0.20833333rem;
  box-sizing: border-box;
  transition: all .5s;
  background: linear-gradient(rgba(78, 153, 253, 0.6), rgba(78, 153, 253, 0.6)),url(@/asset/smart-traffic/ic_traffic_90.webp) no-repeat center/cover;
}
.advantage .list .item .hover .hover-title[data-v-7597b928] {
  font-size: 0.14583333rem;
  color: #fff;
  margin-bottom: 0.04166667rem;
}
.advantage .list .item .hover .hover-en-title[data-v-7597b928] {
  font-size: 0.09375rem;
  color: rgba(255,255,255,.6);
  margin-bottom: 0.20833333rem;
}
.advantage .list .item .hover .hover-brief[data-v-7597b928] {
  font-size: 0.08333333rem;
  color: #fff;
  line-height: 2;
}
.advantage .list .item:hover .content[data-v-7597b928] {
  opacity: 0;
}
.advantage .list .item:hover .hover[data-v-7597b928] {
  opacity: 1;
}
.advantage .list .item:nth-child(2) .hover[data-v-7597b928] {
  background: linear-gradient(rgba(78, 153, 253, 0.6), rgba(78, 153, 253, 0.6)),url("@/asset/smart-traffic/ic_traffic_87.webp") no-repeat center/cover;
}
.advantage .list .item:nth-child(3) .hover[data-v-7597b928] {
  background: linear-gradient(rgba(78, 153, 253, 0.6), rgba(78, 153, 253, 0.6)),url("@/asset/smart-traffic/ic_traffic_88.webp") no-repeat center/cover;
}
.advantage .list .item:nth-child(4) .hover[data-v-7597b928] {
  background: linear-gradient(rgba(78, 153, 253, 0.6), rgba(78, 153, 253, 0.6)),url("@/asset/smart-traffic/ic_traffic_89.webp") no-repeat center/cover;
}
.drive[data-v-7597b928] {
  line-height: 1;
  padding: 0.41666667rem 0;
  transition: .5s all;
  background: url(@/asset/smart-traffic/ic_traffic_43.png) no-repeat center/cover;
}
.drive .en-title[data-v-7597b928] {
  font-size: 0.1875rem;
  color: rgba(255,255,255,.4);
  margin-bottom: 0.05208333rem;
  text-align: center;
}
.drive .ch-title[data-v-7597b928] {
  font-size: 0.1875rem;
  color: #fff;
  margin-bottom: 0.17708333rem;
  text-align: center;
  font-family: YouSheBiaoTiHei;
}
.drive .ch-brief[data-v-7597b928] {
  font-size: 0.10416667rem;
  color: rgba(255,255,255,.6);
  text-align: center;
  margin-bottom: 0.41666667rem;
}
.drive .content[data-v-7597b928] {
  display: flex;
  height: 2.1875rem;
}
.drive .content .left[data-v-7597b928] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 0.01041667rem solid rgba(255,255,255,.6);
  flex-shrink: 0;
}
.drive .content .left .item[data-v-7597b928] {
  display: flex;
  align-items: center;
  gap: 0.04166667rem;
  padding-right: 0.20833333rem;
  cursor: pointer;
}
.drive .content .left .item .item-icon[data-v-7597b928] {
  width: 0.10416667rem;
}
.drive .content .left .item .item-txt[data-v-7597b928] {
  font-size: 0.10416667rem;
  font-weight: bold;
  color: #fff;
}
.drive .content .left .item-strip[data-v-7597b928] {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  width: 0.02083333rem;
  height: 25%;
  transition: all .5s;
  margin-right: -0.00520833rem;
  background: #4e99fd;
}
.drive .content .right[data-v-7597b928] {
  padding-left: 0.41666667rem;
}
.drive .content .right .item .title[data-v-7597b928] {
  font-size: 0.1875rem;
  color: #fff;
}
.drive .content .right .item .brief[data-v-7597b928] {
  font-size: 0.10416667rem;
  line-height: 2;
  margin: 0.20833333rem 0;
  color: rgba(255,255,255,.6);
}
.more-button[data-v-7597b928] {
  width: 1.13020833rem;
  height: 0.31770833rem;
  background: #4e99fd;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.0625rem;
  cursor: pointer;
}
.more-button .more-icon[data-v-7597b928] {
  width: 0.15625rem;
}
.core[data-v-7597b928] {
  padding: 0.41666667rem 0;
}
.footer-resolution[data-v-7597b928] {
  height: 1.58333333rem;
  background-size: cover;
}
.footer-resolution .layout[data-v-7597b928] {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.10416667rem;
}
.footer-resolution .f-title[data-v-7597b928] {
  font-family: YouSheBiaoTiHei,YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 0.1875rem;
  color: #333;
  margin-top: 0.3125rem;
}
.footer-resolution .f-en[data-v-7597b928] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.05208333rem;
}
.footer-resolution .f-sub[data-v-7597b928] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.20833333rem;
}
.footer-resolution .ask-btn[data-v-7597b928] {
  width: 1.04166667rem;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.52083333rem;
}