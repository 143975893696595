body {
  overflow-x: hidden;
}
.hideTxt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#root {
  margin: 0 auto;
  padding-bottom: 0.44270833rem;
}
.content-limit {
  max-width: 6.66666667rem;
  margin: 0 auto;
}