.relative[data-v-4a2bd96d] {
  position: relative;
}
.w-full[data-v-4a2bd96d] {
  width: 100%;
}
.relative-center[data-v-4a2bd96d] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-4a2bd96d] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-4a2bd96d] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-4a2bd96d] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-4a2bd96d] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-4a2bd96d] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-4a2bd96d] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-4a2bd96d] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-4a2bd96d] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-4a2bd96d] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-4a2bd96d] {
  justify-content: space-between;
}
.flex[data-v-4a2bd96d] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-4a2bd96d] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-4a2bd96d],
.card-list-no-padding .ci-description[data-v-4a2bd96d] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.structure[data-v-4a2bd96d] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure .img[data-v-4a2bd96d] {
  display: block;
  margin-top: 0.20833333rem;
}
.feature[data-v-4a2bd96d] {
  background: url(@/asset/smart-traffic/ic_traffic_65.png) no-repeat center/cover;
  padding: 0.41666667rem 0 0.20833333rem;
}
.footer-resolution[data-v-4a2bd96d] {
  height: 1.58333333rem;
  background: url(@/asset/smart-traffic/ic_traffic_86.png) no-repeat center/cover;
}
.footer-resolution .layout[data-v-4a2bd96d] {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.10416667rem;
}
.footer-resolution .f-title[data-v-4a2bd96d] {
  font-family: YouSheBiaoTiHei,YouSheBiaoTiHei;
  font-weight: 400;
  font-size: 0.1875rem;
  color: #333;
  margin-top: 0.3125rem;
}
.footer-resolution .f-en[data-v-4a2bd96d] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.05208333rem;
}
.footer-resolution .f-sub[data-v-4a2bd96d] {
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #999;
  margin-top: 0.20833333rem;
}
.footer-resolution .ask-btn[data-v-4a2bd96d] {
  width: 1.04166667rem;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  box-shadow: 0.015625rem 0.015625rem 0.04166667rem 0.00520833rem #e8e8e8;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.52083333rem;
}
.application[data-v-4a2bd96d] {
  padding: 0.41666667rem 0;
}
.application .brief[data-v-4a2bd96d] {
  font-size: 0.09375rem;
  color: #666;
  line-height: 2;
  margin-top: 0.35416667rem;
}
.application .list[data-v-4a2bd96d] {
  display: flex;
  justify-content: space-between;
  gap: 0.20833333rem;
  margin-top: 0.36458333rem;
}
.application .list .item[data-v-4a2bd96d] {
  flex-grow: 1;
}