.relative[data-v-d3890e26] {
  position: relative;
}
.w-full[data-v-d3890e26] {
  width: 100%;
}
.relative-center[data-v-d3890e26] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-d3890e26] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-d3890e26] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-d3890e26] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-d3890e26] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-d3890e26] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-d3890e26] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-d3890e26] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-d3890e26] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-d3890e26] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-d3890e26] {
  justify-content: space-between;
}
.flex[data-v-d3890e26] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-d3890e26] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-d3890e26],
.card-list-no-padding .ci-description[data-v-d3890e26] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.structure[data-v-d3890e26] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.structure .card-box[data-v-d3890e26] {
  margin-bottom: 0.41666667rem;
}
.structure .img[data-v-d3890e26] {
  display: block;
  margin-top: 0.20833333rem;
}
.system[data-v-d3890e26] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/smart-traffic/ic_traffic_21.png) no-repeat center/cover;
}
.advantage[data-v-d3890e26] {
  padding: 0.41666667rem 0;
}
.service[data-v-d3890e26] {
  padding: 0.41666667rem 0;
  background: url(@/asset/smart-traffic/ic_traffic_21.png) no-repeat center/cover;
}