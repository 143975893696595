.relative[data-v-a78ff1d4] {
  position: relative;
}
.w-full[data-v-a78ff1d4] {
  width: 100%;
}
.relative-center[data-v-a78ff1d4] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-a78ff1d4] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-a78ff1d4] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-a78ff1d4] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-a78ff1d4] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-a78ff1d4] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-a78ff1d4] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-a78ff1d4] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-a78ff1d4] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-a78ff1d4] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-a78ff1d4] {
  justify-content: space-between;
}
.flex[data-v-a78ff1d4] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-a78ff1d4] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-a78ff1d4],
.card-list-no-padding .ci-description[data-v-a78ff1d4] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
[data-v-a78ff1d4] .nav {
  height: 2.645833rem !important;
}
[data-v-a78ff1d4] .nav .content-limit {
  height: calc(100% - 1.52083333rem);
}
[data-v-a78ff1d4] .nav .content-limit .txt {
  margin-top: .2rem;
}
.nav[data-v-a78ff1d4] {
  padding-top: 27%;
  height: unset;
}
.nav[data-v-a78ff1d4] .nav {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0 !important;
  height: 100%;
  width: 100%;
}
.nav[data-v-a78ff1d4] .txt {
  margin-top: 0.26041667rem !important;
}
.hover-pop-back[data-v-a78ff1d4] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(10,10,10,.6);
  z-index: 999;
}
.hover-pop-content[data-v-a78ff1d4] {
  width: 4.16666667rem;
  padding: 0.171875rem 0.625rem;
  box-sizing: border-box;
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 1.77083333rem;
  transform: translateX(-50%);
  background: #fff;
  border-top-left-radius: 0.04166667rem;
  border-top-right-radius: 0.04166667rem;
  background-size: 100% 100% !important;
}
.hover-pop-title[data-v-a78ff1d4] {
  font-weight: 500;
  color: #3097ef;
  font-size: 0.1875rem;
  text-align: center;
  margin-bottom: 0.3125rem;
}
.hover-pop-item[data-v-a78ff1d4] {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.0625rem;
  color: red;
}
.hover-pop-icon[data-v-a78ff1d4] {
  width: 0.10416667rem;
  flex-shrink: 0;
  margin-top: 0.05208333rem;
}
.hover-pop-txt[data-v-a78ff1d4] {
  width: 0.46875rem;
  font-size: 0.10416667rem;
  color: #4e99fd;
  margin: 0 0.08333333rem 0 0.04166667rem;
  flex-shrink: 0;
  text-align-last: justify;
  position: relative;
  margin-top: 0.05208333rem;
}
.hover-pop-txt[data-v-a78ff1d4]::after {
  content: ":";
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.hover-pop-input[data-v-a78ff1d4] {
  flex-grow: 1;
  background: #fff;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  border: 0.00520833rem solid #e8e8e8;
  font-size: 0.08333333rem;
  height: 0.20833333rem;
  padding: 0 0.10416667rem;
  outline: none;
  color: #666;
}
.hover-pop-input[data-v-a78ff1d4]::placeholder {
  color: #ccc;
}
.hover-pop-check-icon[data-v-a78ff1d4] {
  width: 0.625rem;
  height: 0.20833333rem;
  margin-right: 0.08333333rem;
  cursor: pointer;
}
.hover-pop-area[data-v-a78ff1d4] {
  height: 0.72916667rem;
  resize: none;
  padding-top: 0.05208333rem;
  box-sizing: border-box;
}
.hover-pop-button[data-v-a78ff1d4] {
  font-size: 0.125rem;
  color: #fff;
  width: 2.06770833rem;
  height: 0.25rem;
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  text-align: center;
  line-height: 0.25rem;
  margin: 0 auto;
  margin-top: 0.125rem;
  cursor: pointer;
}
.hover-pop-success[data-v-a78ff1d4] {
  display: none;
  width: 3.421875rem;
  height: 2.70833333rem;
  padding: 0.20833333rem 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 0.08333333rem;
}
.hover-pop-success-img[data-v-a78ff1d4] {
  width: 0.9375rem;
  margin: 0 auto;
}
.hover-pop-success-title[data-v-a78ff1d4] {
  color: #1dda67;
  font-size: 0.1875rem;
  margin-top: 0.20833333rem;
}
.hover-pop-success-subtitle[data-v-a78ff1d4] {
  color: #666;
  font-size: 0.08333333rem;
  margin-top: 0.125rem;
  margin-bottom: 0.44270833rem;
}
.hover-pop-success-info[data-v-a78ff1d4] {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 0.09375rem;
  justify-content: center;
}
.hover-pop-success-phone[data-v-a78ff1d4] {
  margin-right: 0.04166667rem;
}
.hover-pop-success-sp[data-v-a78ff1d4] {
  font-size: 0.125rem;
  color: #3097ef;
  margin-left: 0.08333333rem;
}
.hover-pop-close[data-v-a78ff1d4] {
  position: absolute;
  width: 0.08333333rem;
  right: 0.125rem;
  top: 0.125rem;
  cursor: pointer;
}
.private[data-v-a78ff1d4] {
  font-size: 0.07291667rem;
  color: #999;
  text-align: center;
  margin-top: 0.3125rem;
}
.private .sp[data-v-a78ff1d4] {
  color: #4e99fd;
  cursor: pointer;
}
.en-bannerTxt[data-v-a78ff1d4] {
  font-family: YouSheBiaoTiHei;
  font-size: 0.3125rem;
  color: #fff;
  margin-bottom: 0.20833333rem;
  text-align: center;
}
.bannerTxt[data-v-a78ff1d4] {
  font-size: 0.15625rem;
  color: rgba(255,255,255,.8);
  text-align: center;
}
.privateBox[data-v-a78ff1d4] {
  display: none;
}
.privateBox .private-back[data-v-a78ff1d4] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
  z-index: 999;
}
.privateBox .private-content[data-v-a78ff1d4] {
  position: fixed;
  width: 4.16666667rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.privateBox .private-content .private-title[data-v-a78ff1d4] {
  color: #fff;
  font-size: 0.08333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.3125rem;
  background: #4e99fd;
  position: relative;
}
.privateBox .private-content .private-title .private-close[data-v-a78ff1d4] {
  position: absolute;
  right: 0.125rem;
  top: 0.11458333rem;
  width: 0.08333333rem;
  cursor: pointer;
}
.privateBox .private-content .private-inner[data-v-a78ff1d4] {
  font-size: 0.07291667rem;
  line-height: 2;
  background: #fff;
  text-align: left;
  padding: 0.20833333rem;
  padding-top: 0.05208333rem;
}
.privateBox .private-content .private-inner .private-h1[data-v-a78ff1d4] {
  color: #666;
}
.privateBox .private-content .private-inner .private-h2[data-v-a78ff1d4] {
  color: #333;
}
.privateBox .private-content .private-inner .private-h3[data-v-a78ff1d4] {
  color: #999;
}
.privateBox .private-content .private-inner .private-button[data-v-a78ff1d4] {
  width: 0.75rem;
  height: 0.25rem;
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0.26041667rem;
  cursor: pointer;
}