.relative[data-v-85b856b4] {
  position: relative;
}
.w-full[data-v-85b856b4] {
  width: 100%;
}
.relative-center[data-v-85b856b4] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-85b856b4] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-85b856b4] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-85b856b4] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-85b856b4] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-85b856b4] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-85b856b4] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-85b856b4] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-85b856b4] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-85b856b4] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-85b856b4] {
  justify-content: space-between;
}
.flex[data-v-85b856b4] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-85b856b4] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-85b856b4],
.card-list-no-padding .ci-description[data-v-85b856b4] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.privateBox .private-back[data-v-85b856b4],
.hover-pop-success .private-back[data-v-85b856b4] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
  z-index: 9999;
}
.privateBox .pd-name[data-v-85b856b4],
.hover-pop-success .pd-name[data-v-85b856b4] {
  font-size: 0.125rem;
  color: #096dd9;
  margin-top: 0.41666667rem;
  padding-left: 0.52083333rem;
}
.privateBox .pd-content[data-v-85b856b4],
.hover-pop-success .pd-content[data-v-85b856b4] {
  display: flex;
  align-items: center;
  gap: 0.41666667rem;
  padding: 0.20833333rem 0.125rem 0.41666667rem 0.52083333rem;
  height: 2.13541667rem;
}
.privateBox .pd-content .pd-left[data-v-85b856b4],
.hover-pop-success .pd-content .pd-left[data-v-85b856b4] {
  width: 2.13541667rem;
  height: 2.13541667rem;
  background: #fff;
  box-shadow: 0.02604167rem 0.04166667rem 0.10416667rem 0 #dadada;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.privateBox .pd-content .pd-left .pd-img[data-v-85b856b4],
.hover-pop-success .pd-content .pd-left .pd-img[data-v-85b856b4] {
  width: 1.96354167rem;
}
.privateBox .pd-content .pd-right[data-v-85b856b4],
.hover-pop-success .pd-content .pd-right[data-v-85b856b4] {
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  line-height: 2;
  font-size: 0.08333333rem;
  padding-right: 0.36458333rem;
}
.privateBox .pd-content .pd-right[data-v-85b856b4]::-webkit-scrollbar,
.hover-pop-success .pd-content .pd-right[data-v-85b856b4]::-webkit-scrollbar {
  width: 0.03125rem;
}
.privateBox .pd-content .pd-right[data-v-85b856b4]::-webkit-scrollbar-thumb,
.hover-pop-success .pd-content .pd-right[data-v-85b856b4]::-webkit-scrollbar-thumb {
  border-radius: 0.05208333rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
}
.privateBox .pd-content .pd-right[data-v-85b856b4]::-webkit-scrollbar-track,
.hover-pop-success .pd-content .pd-right[data-v-85b856b4]::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.privateBox .pd-content .pd-right .pd-brief[data-v-85b856b4],
.hover-pop-success .pd-content .pd-right .pd-brief[data-v-85b856b4] {
  color: #666;
  margin-bottom: 0.04166667rem;
}
.privateBox .pd-content .pd-right .pd-feature[data-v-85b856b4],
.hover-pop-success .pd-content .pd-right .pd-feature[data-v-85b856b4] {
  color: #096dd9;
  margin-bottom: 0.04166667rem;
}
.privateBox .private-content[data-v-85b856b4],
.hover-pop-success .private-content[data-v-85b856b4] {
  position: fixed;
  width: 6.66666667rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding-bottom: 0.20833333rem;
}
.privateBox .private-content .private-title[data-v-85b856b4],
.hover-pop-success .private-content .private-title[data-v-85b856b4] {
  color: #fff;
  font-size: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  position: relative;
}
.privateBox .private-content .private-title .private-close[data-v-85b856b4],
.hover-pop-success .private-content .private-title .private-close[data-v-85b856b4] {
  position: absolute;
  right: 0.125rem;
  top: 0.11458333rem;
  width: 0.08333333rem;
  cursor: pointer;
}
.privateBox .private-content .private-button[data-v-85b856b4],
.hover-pop-success .private-content .private-button[data-v-85b856b4] {
  margin: 0 auto;
  width: 0.81770833rem;
  height: 0.25rem;
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.bannerTxt[data-v-85b856b4] {
  font-family: YouSheBiaoTiHei;
  font-size: 0.3125rem;
  color: #fff;
  margin-bottom: 0.125rem;
  text-align: center;
}
.head-txt[data-v-85b856b4] {
  font-weight: 400;
  font-size: 0.15625rem;
  color: #fff;
  text-align: center;
  margin-top: 0.20833333rem;
}
.service[data-v-85b856b4] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.service .list[data-v-85b856b4] {
  width: 6.66666667rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.3125rem 0.125rem;
  margin-top: 0.41666667rem;
}
.service .list .item[data-v-85b856b4] {
  background: #fff;
  box-shadow: 0.015625rem 0.015625rem 0.10416667rem 0.00520833rem rgba(221,221,221,.4);
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  width: 2.13541667rem;
  height: 2.29166667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.service .list .item .img[data-v-85b856b4] {
  width: 80%;
}
.service .list .item .hover[data-v-85b856b4] {
  position: absolute;
  left: 0.0625rem;
  right: 0.0625rem;
  bottom: 0.22395833rem;
  transition: all .5s;
  border-radius: 0.04166667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.10416667rem;
  color: #666;
  height: 1em;
}
.service .list .item:hover .hover[data-v-85b856b4] {
  background: rgba(78,153,253,.6);
  height: 0.91145833rem;
  color: #fff;
}
.theory[data-v-85b856b4] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/product/ic_product_14.png) no-repeat center/cover;
}
.theory .step[data-v-85b856b4] {
  display: flex;
  align-items: flex-start;
  margin-top: 0.41666667rem;
}
.theory .step .item:first-child .middle[data-v-85b856b4] {
  border-radius: 0.08333333rem 0 0 0.08333333rem;
}
.theory .step .item:last-child .middle[data-v-85b856b4] {
  border-radius: 0 0.08333333rem 0.08333333rem 0;
}
.theory .step .item1[data-v-85b856b4] {
  flex-grow: 1;
}
.theory .step .item1 .top[data-v-85b856b4] {
  background: #4e99fd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1875rem;
  width: 1.09375rem;
  height: 0.66145833rem;
}
.theory .step .item1 .top .icon[data-v-85b856b4] {
  height: 0.3125rem;
}
.theory .step .item1 .middle[data-v-85b856b4] {
  background: #deecf6;
  margin-bottom: 0.15625rem;
  width: 100%;
  height: 0.22916667rem;
  position: relative;
}
.theory .step .item1 .middle .icon[data-v-85b856b4] {
  width: 0.29166667rem;
  height: 0.29166667rem;
  background: url(@/asset/product/ic_product_20.png) no-repeat center/contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.125rem;
  color: #4e99fd;
  text-align: center;
  line-height: 0.29166667rem;
}
.theory .step .item1 .bottom[data-v-85b856b4] {
  color: #666;
  font-size: 0.16666667rem;
  text-align: center;
}
.theory .step .item2[data-v-85b856b4] {
  flex-grow: 1;
}
.theory .step .item2 .top[data-v-85b856b4] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.1875rem;
  width: 0.76041667rem;
  height: 0.66145833rem;
}
.theory .step .item2 .top .icon[data-v-85b856b4] {
  width: 0.50520833rem;
}
.theory .step .item2 .middle[data-v-85b856b4] {
  background: #deecf6;
  width: 100%;
  height: 0.22916667rem;
}
.display[data-v-85b856b4] {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.41666667rem;
}
.display .left[data-v-85b856b4] {
  box-shadow: -0.05208333rem 0.05208333rem 0 #ddd;
  width: 2.96354167rem;
  height: 1.00520833rem;
  padding: 0.33333333rem;
  box-sizing: border-box;
  border-radius: 0.04166667rem;
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  margin-right: -0.33333333rem;
  position: relative;
}
.display .left .title[data-v-85b856b4] {
  width: 100%;
  font-size: 0.10416667rem;
  margin-bottom: 0.10416667rem;
  color: #4e99fd;
}
.display .left .brief[data-v-85b856b4] {
  width: 100%;
  font-size: 0.10416667rem;
  color: #666;
}
.display .right[data-v-85b856b4] {
  width: 3.42708333rem;
  height: 2.140625rem;
  background: url(@/asset/product/ic_product_21.webp) no-repeat center/cover;
  box-shadow: -0.05208333rem 0.05208333rem 0 #1e63c1;
  border-radius: 0.04166667rem;
}
.feature[data-v-85b856b4] {
  padding: 0.41666667rem 0;
}
.feature .list[data-v-85b856b4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.41666667rem;
}
.feature .list .item[data-v-85b856b4] {
  text-align: center;
}
.feature .list .item .icon[data-v-85b856b4] {
  width: 0.33854167rem;
  margin-bottom: 0.02083333rem;
}
.feature .list .item .title[data-v-85b856b4] {
  font-size: 0.125rem;
  color: #3384e8;
  margin-bottom: 0.06770833rem;
}
.feature .list .item .brief[data-v-85b856b4] {
  font-size: 0.09375rem;
  color: #666;
  line-height: 0.125rem;
  margin: 0;
}
.feature .end[data-v-85b856b4] {
  margin-top: 0.20833333rem;
}
.feature .end .item[data-v-85b856b4] {
  display: flex;
  gap: 0.46875rem;
  margin-bottom: 0.41666667rem;
}
.feature .end .item[data-v-85b856b4]:last-child {
  margin-bottom: 0;
}
.feature .end .item[data-v-85b856b4]:nth-child(2n) {
  flex-direction: row-reverse;
}
.feature .end .item:nth-child(2n) .title[data-v-85b856b4] {
  text-align: right;
}
.feature .end .item .txt[data-v-85b856b4] {
  flex: 1;
}
.feature .end .item .txt .title[data-v-85b856b4] {
  font-size: 0.125rem;
  color: #0865aa;
  margin-bottom: 0.15625rem;
}
.feature .end .item .txt .brief[data-v-85b856b4] {
  font-size: 0.09375rem;
  color: #999;
  line-height: 2;
}
.feature .end .item .img[data-v-85b856b4] {
  flex: 1;
}