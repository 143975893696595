.relative[data-v-6d16cb50] {
  position: relative;
}
.w-full[data-v-6d16cb50] {
  width: 100%;
}
.relative-center[data-v-6d16cb50] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-6d16cb50] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-6d16cb50] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-6d16cb50] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-6d16cb50] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-6d16cb50] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-6d16cb50] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-6d16cb50] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-6d16cb50] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-6d16cb50] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-6d16cb50] {
  justify-content: space-between;
}
.flex[data-v-6d16cb50] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-6d16cb50] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-6d16cb50],
.card-list-no-padding .ci-description[data-v-6d16cb50] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.partner[data-v-6d16cb50] {
  padding: 0.41666667rem 0;
  position: relative;
  z-index: 0;
}
.partner[data-v-6d16cb50]::before {
  content: "";
  position: absolute;
  width: calc((100% - 7.5rem)/2 + 1.5625rem);
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0.2));
  z-index: 9;
}
.partner[data-v-6d16cb50]::after {
  content: "";
  position: absolute;
  width: calc((100% - 7.5rem)/2 + 1.5625rem);
  height: 100%;
  right: 0;
  top: 0;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0.2));
  z-index: 9;
}
.partner .list[data-v-6d16cb50] {
  position: relative;
  margin-top: 0.15625rem;
  overflow: hidden;
  height: 0.76041667rem;
}
.partner .list .item[data-v-6d16cb50] {
  position: absolute;
  width: 1.1875rem;
  height: 0.76041667rem;
}
.partner .list .item img[data-v-6d16cb50] {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.partner .button[data-v-6d16cb50] {
  color: #fff;
  font-size: 0.125rem;
  width: 1.13020833rem;
  height: 0.31770833rem;
  margin: 0 auto;
  margin-top: 0.34375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(180deg, #3197f1 0%, #3384e8 100%);
}
.partner .button .icon[data-v-6d16cb50] {
  width: 0.15625rem;
}
.partner .p-container[data-v-6d16cb50] {
  mask: radial-gradient(circle at center, black, rgba(0, 0, 0, 0));
}