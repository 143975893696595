.relative[data-v-4e4d01da] {
  position: relative;
}
.w-full[data-v-4e4d01da] {
  width: 100%;
}
.relative-center[data-v-4e4d01da] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-4e4d01da] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-4e4d01da] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-4e4d01da] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-4e4d01da] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-4e4d01da] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-4e4d01da] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-4e4d01da] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-4e4d01da] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-4e4d01da] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-4e4d01da] {
  justify-content: space-between;
}
.flex[data-v-4e4d01da] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-4e4d01da] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-4e4d01da],
.card-list-no-padding .ci-description[data-v-4e4d01da] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.list[data-v-4e4d01da] {
  display: flex;
  flex-wrap: wrap;
  gap: 0.08333333rem;
  margin-top: 0.41666667rem;
}
.list .item[data-v-4e4d01da] {
  width: calc(33.3% - 0.08333333rem);
  position: relative;
  height: 1.38541667rem;
}
.list .item .hover[data-v-4e4d01da] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 0.20833333rem;
  background: rgba(51,51,51,.4);
  flex-wrap: wrap;
  text-align: center;
}
.list .item .hover .name[data-v-4e4d01da] {
  width: 100%;
  font-size: 0.125rem;
  color: #fff;
}
.list .item .hover .button[data-v-4e4d01da] {
  width: 0.625rem;
  height: 0.20833333rem;
  line-height: 0.20833333rem;
  text-align: center;
  font-size: 0.09375rem;
  color: #fff;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  border: 0.00520833rem solid #fff;
  cursor: pointer;
}