.relative[data-v-33d21d20] {
  position: relative;
}
.w-full[data-v-33d21d20] {
  width: 100%;
}
.relative-center[data-v-33d21d20] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-33d21d20] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-33d21d20] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-33d21d20] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-33d21d20] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-33d21d20] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-33d21d20] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-33d21d20] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-33d21d20] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-33d21d20] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-33d21d20] {
  justify-content: space-between;
}
.flex[data-v-33d21d20] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-33d21d20] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-33d21d20],
.card-list-no-padding .ci-description[data-v-33d21d20] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.prod-display[data-v-33d21d20] {
  padding: 0.41666667rem 0.72916667rem 0;
}
.prod-display .prod-tabs[data-v-33d21d20] {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.prod-display .prod-tabs .prod-item[data-v-33d21d20] {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.125rem;
  color: #666;
  cursor: pointer;
}
.prod-display .prod-tabs .prod-item .prod-icon[data-v-33d21d20] {
  height: 0.16666667rem;
  margin-right: 0.08333333rem;
}
.prod-display .prod-tabs .prod-item .prod-txt[data-v-33d21d20] {
  padding: 0 0.02604167rem;
  box-sizing: border-box;
}
.prod-display .prod-tabs .prod-item[data-v-33d21d20]:hover {
  color: #096dd9;
}
.prod-display .prod-tabs .prod-active[data-v-33d21d20] {
  color: #096dd9;
  flex-wrap: wrap;
}
.prod-display .prod-tabs .prod-active .prod-icon[data-v-33d21d20] {
  height: 0.41666667rem;
  margin-right: 0;
}
.prod-display .prod-tabs .prod-active .prod-txt[data-v-33d21d20] {
  width: 100%;
  margin-top: 0.08333333rem;
}
.prod-display .prod-list[data-v-33d21d20] {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0.41666667rem;
  gap: 0.13020833rem;
}
.prod-display .prod-list .prod-item[data-v-33d21d20] {
  width: calc(33% - 0.13020833rem);
  box-sizing: border-box;
  padding: 0.10416667rem;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0.02604167rem 0.04166667rem 0.10416667rem 0 #dadada;
  border-radius: 0.04166667rem;
}
.prod-display .prod-list .prod-item .prod-inner[data-v-33d21d20] {
  position: relative;
}
.prod-display .prod-list .prod-item .prod-inner .prod-img[data-v-33d21d20] {
  width: 100%;
  height: 2.29166667rem;
}
.prod-display .prod-list .prod-item .prod-inner .prod-hover[data-v-33d21d20] {
  position: absolute;
  border-radius: 0.04166667rem;
  width: calc(100% - 0.16666667rem);
  height: 0.10416667rem;
  left: 0.08333333rem;
  bottom: 0.08333333rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  transition: all .2s;
  font-size: 0.09375rem;
  color: #666;
}
.prod-display .prod-list .prod-item .prod-inner .prod-hover .prod-innerTxt[data-v-33d21d20] {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.prod-display .prod-list .prod-item .prod-inner:hover .prod-hover[data-v-33d21d20] {
  opacity: .8;
  height: 40%;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  color: #fff;
  font-size: 0.10416667rem;
}
.prod-display .prod-list .prod-item .prod-inner:hover .prod-hover .prod-innerTxt[data-v-33d21d20] {
  white-space: normal;
}
.product[data-v-33d21d20] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.prod-button[data-v-33d21d20] {
  font-size: 0.125rem;
  color: #fffdfd;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.10416667rem 0;
  margin: 0 auto;
  margin-top: 0.20833333rem;
  width: 1.13020833rem;
  cursor: pointer;
  gap: 0.0625rem;
}
.prod-button .icon[data-v-33d21d20] {
  width: 0.15625rem;
}
.privateBox .private-back[data-v-33d21d20],
.hover-pop-success .private-back[data-v-33d21d20] {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
  z-index: 999;
}
.privateBox .private-content[data-v-33d21d20],
.hover-pop-success .private-content[data-v-33d21d20] {
  position: fixed;
  width: 6.66666667rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
.privateBox .private-content .private-title[data-v-33d21d20],
.hover-pop-success .private-content .private-title[data-v-33d21d20] {
  color: #fff;
  font-size: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.3125rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
  position: relative;
}
.privateBox .private-content .private-title .private-close[data-v-33d21d20],
.hover-pop-success .private-content .private-title .private-close[data-v-33d21d20] {
  position: absolute;
  right: 0.125rem;
  top: 0.11458333rem;
  width: 0.08333333rem;
  cursor: pointer;
}
.privateBox .private-content .private-button[data-v-33d21d20],
.hover-pop-success .private-content .private-button[data-v-33d21d20] {
  width: 0.81770833rem;
  height: 0.25rem;
  background: linear-gradient(180deg, #4e99fd 0%, #3384e8 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.10416667rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pd-buttons[data-v-33d21d20] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.20833333rem;
  margin-bottom: 0.36458333rem;
}
.pd-buttons .pd-button[data-v-33d21d20] {
  font-size: 0.10416667rem;
  color: #096dd9;
  height: 0.25rem;
  width: 0.81770833rem;
  border: 0.00520833rem solid #096dd9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.01041667rem;
}
.pd-name[data-v-33d21d20] {
  font-size: 0.125rem;
  color: #096dd9;
  margin-top: 0.41666667rem;
  padding-left: 0.52083333rem;
}
.pd-content[data-v-33d21d20] {
  display: flex;
  align-items: center;
  gap: 0.41666667rem;
  padding: 0.20833333rem 0.125rem 0.41666667rem 0.52083333rem;
  height: 2.13541667rem;
}
.pd-content .pd-left[data-v-33d21d20] {
  width: 2.13541667rem;
  height: 2.13541667rem;
  background: #fff;
  box-shadow: 0.02604167rem 0.04166667rem 0.10416667rem 0 #dadada;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pd-content .pd-left .pd-img[data-v-33d21d20] {
  width: 1.96354167rem;
}
.pd-content .pd-right[data-v-33d21d20] {
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
  line-height: 2;
  font-size: 0.08333333rem;
  padding-right: 0.36458333rem;
}
.pd-content .pd-right[data-v-33d21d20]::-webkit-scrollbar {
  width: 0.03125rem;
}
.pd-content .pd-right[data-v-33d21d20]::-webkit-scrollbar-thumb {
  border-radius: 0.05208333rem;
  background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
}
.pd-content .pd-right[data-v-33d21d20]::-webkit-scrollbar-track {
  background: #e7e7e7;
}
.pd-content .pd-right .pd-brief[data-v-33d21d20] {
  color: #666;
  margin-bottom: 0.04166667rem;
}
.pd-content .pd-right .pd-feature[data-v-33d21d20] {
  color: #096dd9;
  margin-bottom: 0.04166667rem;
}