.relative[data-v-66ab6ebe] {
  position: relative;
}
.w-full[data-v-66ab6ebe] {
  width: 100%;
}
.relative-center[data-v-66ab6ebe] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-66ab6ebe] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-66ab6ebe] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-66ab6ebe] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-66ab6ebe] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-66ab6ebe] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-66ab6ebe] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-66ab6ebe] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-66ab6ebe] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-66ab6ebe] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-66ab6ebe] {
  justify-content: space-between;
}
.flex[data-v-66ab6ebe] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-66ab6ebe] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-66ab6ebe],
.card-list-no-padding .ci-description[data-v-66ab6ebe] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.smart-city .use-bg[data-v-66ab6ebe] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.smart-city .b3-img[data-v-66ab6ebe] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 4.45833333rem;
}
.smart-city .advantage .content[data-v-66ab6ebe] {
  display: flex;
  justify-content: center;
  gap: 0.125rem;
  margin-top: 0.3125rem;
}
.smart-city .advantage .content .left[data-v-66ab6ebe] {
  color: #fff;
  background: url(@/asset/index/ic_index_21.png) no-repeat center/cover;
  padding: 0.36458333rem 0.20833333rem;
  width: 2.22395833rem;
  height: 3.578125rem;
  box-sizing: border-box;
  flex-shrink: 0;
}
.smart-city .advantage .content .left .icon[data-v-66ab6ebe] {
  width: 0.3125rem;
  margin-bottom: 0.20833333rem;
}
.smart-city .advantage .content .left .title[data-v-66ab6ebe] {
  font-size: 0.14583333rem;
  margin-bottom: 0.04166667rem;
}
.smart-city .advantage .content .left .en-title[data-v-66ab6ebe] {
  font-size: 0.0625rem;
  margin-bottom: 0.20833333rem;
}
.smart-city .advantage .content .left .brief[data-v-66ab6ebe] {
  font-size: 0.08333333rem;
  line-height: 2;
}
.smart-city .advantage .content .right[data-v-66ab6ebe] {
  display: flex;
  flex-wrap: wrap;
  width: 3.59375rem;
  gap: 0.125rem;
}
.smart-city .advantage .content .right .item[data-v-66ab6ebe] {
  padding: 0.20833333rem;
  width: 1.70833333rem;
  height: 1.72916667rem;
  box-sizing: border-box;
  border: 0.00520833rem solid #4e99fd;
  border-radius: 0.02083333rem;
}
.smart-city .advantage .content .right .item .icon[data-v-66ab6ebe] {
  height: 0.3125rem;
  margin-bottom: 0.15625rem;
}
.smart-city .advantage .content .right .item .title[data-v-66ab6ebe] {
  font-size: 0.14583333rem;
  color: #333;
  margin-bottom: 0.04166667rem;
}
.smart-city .advantage .content .right .item .en-title[data-v-66ab6ebe] {
  font-size: 0.0625rem;
  color: #999;
  margin-bottom: 0.10416667rem;
}
.smart-city .advantage .content .right .item .brief[data-v-66ab6ebe] {
  font-size: 0.08333333rem;
  color: #666;
  line-height: 2;
}
.smart-city .more-button[data-v-66ab6ebe] {
  width: 1.13020833rem;
  height: 0.31770833rem;
  background: #4e99fd;
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  font-size: 0.125rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.0625rem;
  cursor: pointer;
}
.smart-city .more-button .more-icon[data-v-66ab6ebe] {
  width: 0.15625rem;
}
.smart-city .drive[data-v-66ab6ebe] {
  line-height: 1;
  padding: 0.41666667rem 0;
  transition: .5s all;
}
.smart-city .drive .en-title[data-v-66ab6ebe] {
  font-size: 0.1875rem;
  color: rgba(255,255,255,.4);
  margin-bottom: 0.05208333rem;
  text-align: center;
}
.smart-city .drive .ch-title[data-v-66ab6ebe] {
  font-size: 0.1875rem;
  color: #fff;
  margin-bottom: 0.17708333rem;
  text-align: center;
  font-family: YouSheBiaoTiHei;
}
.smart-city .drive .ch-brief[data-v-66ab6ebe] {
  font-size: 0.10416667rem;
  color: rgba(255,255,255,.6);
  text-align: center;
  margin-bottom: 0.41666667rem;
}
.smart-city .drive .content[data-v-66ab6ebe] {
  display: flex;
  height: 2.1875rem;
}
.smart-city .drive .content .left[data-v-66ab6ebe] {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 0.01041667rem solid rgba(255,255,255,.6);
  flex-shrink: 0;
}
.smart-city .drive .content .left .item[data-v-66ab6ebe] {
  display: flex;
  align-items: center;
  gap: 0.04166667rem;
  padding-right: 0.20833333rem;
  cursor: pointer;
}
.smart-city .drive .content .left .item .item-icon[data-v-66ab6ebe] {
  width: 0.10416667rem;
}
.smart-city .drive .content .left .item .item-txt[data-v-66ab6ebe] {
  font-size: 0.10416667rem;
  font-weight: bold;
  color: #fff;
}
.smart-city .drive .content .left .item-strip[data-v-66ab6ebe] {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  width: 0.02083333rem;
  height: 20%;
  transition: all .5s;
  margin-right: -0.00520833rem;
  background: #4e99fd;
}
.smart-city .drive .content .right[data-v-66ab6ebe] {
  padding-left: 0.41666667rem;
}
.smart-city .drive .content .right .item .title[data-v-66ab6ebe] {
  font-size: 0.1875rem;
  color: #fff;
}
.smart-city .drive .content .right .item .brief[data-v-66ab6ebe] {
  font-size: 0.10416667rem;
  line-height: 2;
  margin: 0.20833333rem 0;
  color: rgba(255,255,255,.6);
}
.smart-city .zig-card[data-v-66ab6ebe] {
  display: flex;
  align-items: center;
}
.smart-city .zig-card .word-context[data-v-66ab6ebe] {
  width: 3rem;
  height: 1.75520833rem;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.00520833rem 0.00520833rem 0.00520833rem 0.00520833rem;
  padding: 0.3125rem;
  display: flex;
  align-items: center;
  transform: translateX(-0.15625rem);
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  color: #666;
  line-height: 0.14583333rem;
  filter: drop-shadow(-0.02604167rem 0.05208333rem 0 rgba(0, 0, 0, 0.25));
}