.relative[data-v-28d7066e] {
  position: relative;
}
.w-full[data-v-28d7066e] {
  width: 100%;
}
.relative-center[data-v-28d7066e] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-28d7066e] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-28d7066e] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-28d7066e] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-28d7066e] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-28d7066e] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-28d7066e] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-28d7066e] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-28d7066e] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-28d7066e] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-28d7066e] {
  justify-content: space-between;
}
.flex[data-v-28d7066e] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-28d7066e] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-28d7066e],
.card-list-no-padding .ci-description[data-v-28d7066e] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.en-bannerTxt[data-v-28d7066e] {
  font-size: 0.3125rem;
  color: rgba(255,255,255,.6);
  text-align: center;
  margin-bottom: 0.04166667rem;
}
.bannerTxt[data-v-28d7066e] {
  font-family: YouSheBiaoTiHei;
  font-size: 0.375rem;
  color: #fff;
  margin-bottom: 0.125rem;
  text-align: center;
}
.box[data-v-28d7066e] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.issue[data-v-28d7066e] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url(@/asset/service/ic_service_22.png) no-repeat center/cover;
}
.issue .display[data-v-28d7066e] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3125rem;
}
.issue .display .item[data-v-28d7066e] {
  position: relative;
}
.issue .display .txt[data-v-28d7066e] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0.22916667rem 0.20833333rem;
  box-sizing: border-box;
}
.issue .display .txt .title[data-v-28d7066e] {
  color: #4e99fd;
  font-size: 0.10416667rem;
  margin-bottom: 0.125rem;
}
.issue .display .txt .brief[data-v-28d7066e] {
  color: #666;
  font-size: 0.07291667rem;
  line-height: 2;
}
.issue .display .img[data-v-28d7066e] {
  width: 100%;
}
.issue .display .bot-txt[data-v-28d7066e] {
  margin-top: 0.19791667rem;
}
.issue .display .left .lt[data-v-28d7066e] {
  width: 2.42708333rem;
}
.issue .display .left .lb[data-v-28d7066e] {
  width: 2.42708333rem;
}
.issue .display .middle[data-v-28d7066e] {
  width: 2.91666667rem;
}
.issue .display .right .rt[data-v-28d7066e] {
  width: 2.42708333rem;
}
.issue .display .right .rb[data-v-28d7066e] {
  width: 2.42708333rem;
}
.industry[data-v-28d7066e] {
  padding: 0.52083333rem 0 0.41666667rem;
}
.service[data-v-28d7066e] {
  padding: 0.41666667rem 0 0.20833333rem;
  background: url("@/asset/service/ic_service_22.png") no-repeat center/cover;
}
.service .list[data-v-28d7066e] {
  display: flex;
  gap: 0.20833333rem;
  margin-top: 0.41666667rem;
}
.service .list .item[data-v-28d7066e] {
  width: 1.5rem;
  height: 2.35416667rem;
  border-radius: 0.04166667rem 0.04166667rem 0.04166667rem 0.04166667rem;
  overflow: hidden;
  border: 0.00520833rem solid rgba(51,51,51,.2);
  position: relative;
}
.service .list .item .content[data-v-28d7066e] {
  text-align: center;
  padding: 0.26041667rem 0.125rem 0;
  transition: all .5s;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.service .list .item .content .outline[data-v-28d7066e] {
  width: 0.36458333rem;
  height: 0.36458333rem;
  position: relative;
  margin: 0 auto;
}
.service .list .item .content .outline .icon[data-v-28d7066e] {
  width: 0.1875rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.service .list .item .content .outline .active[data-v-28d7066e] {
  display: none;
}
.service .list .item .content .outline[data-v-28d7066e]::after {
  content: "";
  border: 0.00520833rem solid rgba(51,51,51,.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: rotate(45deg);
}
.service .list .item .content .title[data-v-28d7066e] {
  font-size: 0.125rem;
  color: #333;
  margin-top: 0.10416667rem;
  margin-bottom: 0.04166667rem;
}
.service .list .item .content .en-title[data-v-28d7066e] {
  font-size: 0.09375rem;
  color: #999;
  margin-bottom: 0.08333333rem;
}
.service .list .item .content .brief[data-v-28d7066e] {
  font-size: 0.08333333rem;
  color: #666;
  line-height: 2;
}
.service .list .item:hover .content[data-v-28d7066e] {
  background: linear-gradient(167deg, #68a9ff 0%, #4e99fd 100%);
}
.service .list .item:hover .title[data-v-28d7066e],
.service .list .item:hover .en-title[data-v-28d7066e],
.service .list .item:hover .brief[data-v-28d7066e] {
  color: #fff;
}
.service .list .item:hover .outline[data-v-28d7066e]::after {
  border: 0.00520833rem solid #fff;
}
.service .list .item:hover .active[data-v-28d7066e] {
  display: block !important;
}
.service .list .item:hover .normal[data-v-28d7066e] {
  display: none !important;
}
.after[data-v-28d7066e] {
  padding: 0.41666667rem 0;
}
.after .list[data-v-28d7066e] {
  display: flex;
  margin-top: 0.41666667rem;
  justify-content: space-between;
}
.after .list .item[data-v-28d7066e] {
  text-align: center;
}
.after .list .item .img[data-v-28d7066e] {
  width: 0.83333333rem;
  margin-bottom: 0.08333333rem;
}
.after .list .item .title[data-v-28d7066e] {
  margin-bottom: 0.10416667rem;
  color: #333;
  font-size: 0.125rem;
}
.after .list .item .brief[data-v-28d7066e] {
  color: #666;
  font-size: 0.08333333rem;
}