.relative[data-v-45675514] {
  position: relative;
}
.w-full[data-v-45675514] {
  width: 100%;
}
.relative-center[data-v-45675514] {
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 0.3125rem;
}
.full-center[data-v-45675514] {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.block-content[data-v-45675514] {
  width: 6.66666667rem;
  margin: 0 auto;
}
.block-content .video-img[data-v-45675514] {
  width: 0.20833333rem;
  height: 0.20833333rem;
  position: absolute;
  top: 1.14583333rem;
  left: 50%;
  margin-left: -0.10416667rem;
}
.block-content .video-title[data-v-45675514] {
  position: absolute;
  bottom: 0;
  width: 3.265625rem;
  height: 0.54166667rem;
  background: linear-gradient(134deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0.0052) 100%);
  border-radius: 0.01041667rem 0.01041667rem 0.01041667rem 0.01041667rem;
  margin-top: 0.125rem;
  font-weight: 500;
  font-size: 0.10416667rem;
  color: #fff;
}
.pt-100px[data-v-45675514] {
  padding-top: 0.52083333rem;
}
.pt-80px[data-v-45675514] {
  padding-top: 0.41666667rem;
}
.pb-80px[data-v-45675514] {
  padding-bottom: 0.41666667rem;
}
.pb-40px[data-v-45675514] {
  padding-bottom: 0.20833333rem;
}
.mt-80px[data-v-45675514] {
  margin-top: 0.41666667rem;
}
.space-between[data-v-45675514] {
  justify-content: space-between;
}
.flex[data-v-45675514] {
  display: flex;
}
.card-list-no-padding .card-item[data-v-45675514] {
  padding: 0 0 0.125rem;
}
.card-list-no-padding .ci-title[data-v-45675514],
.card-list-no-padding .ci-description[data-v-45675514] {
  padding: 0 0.20833333rem;
  text-align: left;
  justify-self: flex-start;
  width: 100%;
  box-sizing: border-box;
}
.bridge-monitor .use-bg[data-v-45675514] {
  background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
  background-size: cover;
}
.bridge-monitor .b2-img[data-v-45675514] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 3.02604167rem;
}
.bridge-monitor .b4-img[data-v-45675514] {
  object-fit: scale-down;
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 3.10416667rem;
}
.bridge-monitor .zap-list[data-v-45675514] {
  display: flex;
  justify-content: space-between;
}
.bridge-monitor .zap-list .zap-item[data-v-45675514] {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bridge-monitor .zap-list .zap-item .zap-title[data-v-45675514] {
  text-align: center;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 500;
  font-size: 0.125rem;
  color: #333;
  line-height: 0.25rem;
}
.bridge-monitor .zig-card[data-v-45675514] {
  display: flex;
  align-items: center;
}
.bridge-monitor .zig-card .word-context[data-v-45675514] {
  width: 3rem;
  height: 1.75520833rem;
  box-sizing: border-box;
  background: #fff;
  border-radius: 0.00520833rem 0.00520833rem 0.00520833rem 0.00520833rem;
  padding: 0.16666667rem 0.3125rem;
  display: flex;
  gap: 0.02604167rem;
  justify-content: center;
  flex-direction: column;
  transform: translateX(-0.15625rem);
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.07291667rem;
  color: #666;
  line-height: 0.14583333rem;
  filter: drop-shadow(-0.02604167rem 0.05208333rem 0 rgba(0, 0, 0, 0.25));
}
.bridge-monitor .zig-card .word-context div[data-v-45675514] {
  position: relative;
}
.bridge-monitor .zig-card .word-context div[data-v-45675514]:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 0.0625rem;
  height: 0.0625rem;
  background: #4e99fd;
  border-radius: 50%;
  transform: translate(-0.10416667rem, 0.04166667rem);
}
.bridge-monitor .img-gallery[data-v-45675514] {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  gap: 0.20833333rem;
  grid-template-areas: "a b c" "a d e";
}
.bridge-monitor .bridge-list[data-v-45675514] {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 0.40625rem;
  margin-top: 0.125rem;
}
.bridge-monitor .bridge-list .btn[data-v-45675514] {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Source Han Sans CN,Source Han Sans CN;
  font-weight: 400;
  font-size: 0.11458333rem;
  color: #666;
  background-color: #fff;
}
.bridge-monitor .bridge-list .btn.selected[data-v-45675514] {
  background: #4e99fd;
  color: #fff;
}
.bridge-monitor .b-image[data-v-45675514] {
  width: 6.66666667rem;
  height: 2.76041667rem;
  object-fit: cover;
}